import Head from 'next/head'
import { useMemo } from 'react'
import { FB_APP_ID, toTitleCase } from '../../lib/Utils'
import useFetchUrl from '../../hooks/useFetchUrl'

interface Props {
  title: string
  body: string
  imageUrl: string
}
const CompanyDetailHead = (props: Props) => {
  const { currentUrl, domain } = useFetchUrl()

  const title = useMemo(() => toTitleCase(props.title), [props.title])

  const description = useMemo(
    () => props.body.replace(/<.*?>/g, '').substring(0, 255) + '...',
    [props.body]
  )

  return (
    <Head>
      <title>{title}</title>

      <meta name="twitter:card" content="summary" key="twitterCard"></meta>
      <meta property="og:url" content={currentUrl} key="ogUrl"></meta>
      <meta property="og:title" content={title} key="ogTitle" />

      <meta name="description" content={description} key="description"></meta>
      <meta
        property="og:description"
        content={description}
        key="ogDescription"></meta>
      <meta
        property="og:image"
        content={props.imageUrl || `${domain}/assets/img/company/default.webp`}
        key="ogImage"></meta>

      <meta property="og:type" content="website" key="ogType"></meta>
      <meta property="fb:app_id" content={FB_APP_ID} key="fbAppId"></meta>
    </Head>
  )
}

export default CompanyDetailHead
