import apolloClient from '../../apollo-client'
import { GET_CLIENT_DETAIL_QUERY } from '../../graphql/company'
import { GetStaticProps } from 'next'
import classes from '../../components/ui/applyCaption/ApplyCaption.module.scss'

import DOMPurify from 'isomorphic-dompurify'
import ICompany from '../../types/Company'

import ApplyCaption from '../../components/ui/applyCaption/ApplyCaption'
import HeroImage from '../../components/ui/image/HeroImage'
import MapRender from '../../components/map/mapRender'
import JobsSearch from '../../components/jobs/JobsSearch'

import RelatedVideos from '../../components/related-videos/RelatedVideos'
import { useContext, useEffect, useState } from 'react'
import LeadContext from '../../contexts/LeadContext'
import LeadAcquisitionModal from '../../components/modals/LeadAcquisitionModal'
import CompanyDetailHead from '../../components/heads/CompanyDetailHead'
import { useRouter } from 'next/router'
import { ListBlankLoadMask } from '../../components/ui/ListBlankLoadMask'

interface Props {
  company: ICompany
}

export default function CompanyDetail({ company }: Props) {
  const router = useRouter()

  const [pageRendered, setPageRendered] = useState(false)
  const [showLeadAcquisition, setShowLeadAcquisition] = useState(false)
  const { setLeadFormStaticData } = useContext(LeadContext)

  useEffect(() => {
    setPageRendered(true)
  }, [])

  if (router.isFallback) {
    return <ListBlankLoadMask />
  }
  const iframeRegex = /<iframe>*(.*?)<\/iframe>/g

  const onApply = (client: ICompany) => {
    if (client.defaultJobPost.applyURL) {
      window.open(client.defaultJobPost.applyURL, '_blank')
      return
    }
    setLeadFormStaticData({
      clientId: client._id,
      campaignId: client.defaultCampaign._id,
      clientName: client.name,
      heroImage: client.resources?.hero?.Location
    })
    setShowLeadAcquisition(true)
  }

  return (
    <>
      <CompanyDetailHead
        title={company.name}
        body={company.body}
        imageUrl={company.resources?.hero?.Location}
      />
      <section className="max-w-[1200px] mx-auto text-[#444]">
        <HeroImage
          alt={`${company.name} Image`}
          srcUrl={company.resources?.hero?.Location}
        />

        <ApplyCaption
          trackingNumber={company?.trackingNumbers[0]?.trackingNumber}
          applyNowTxt={company?.applyNowTxt || undefined} // TODO find out if client is needed to get apply now
          backButtonTxt={'Back'}
          onApply={() => onApply(company)}
          backButtonHref={'/trucking-companies'}
        />
        <div className="md:px-[0px] px-[0.6rem]  md:pt-[20px] pt-[0.5rem] pb-[0px]">
          <h1 className="text-[1.8rem] font-arial-black uppercase mb-2">
            {company.name} Information
          </h1>
          <h2 className="text-[1.2rem] mb-8 font-arial font-bold leading-tight">
            {company.defaultJobPost?.overview}
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                company?.defaultJobPost?.details?.replace(iframeRegex, '')
              )
            }}></div>

          <RelatedVideos text={company?.defaultJobPost?.details} />

          <div className={classes.hiringMapJob}>
            <h3 className={classes.subtitle}>Company Hiring Area</h3>
            <MapRender
              allStatesCoordinates={
                company?.defaultJobPost?.allStatesCoordinates
              }
              hiringArea={company?.defaultJobPost?.hiringArea}></MapRender>
          </div>

          {company?.defaultJobPost?.includeCompanyInfo && (
            <div className={classes.clientInformation}>
              <h3 className={classes.subtitle}>
                {company.name} company Information
              </h3>

              <div
                className={classes.body}
                dangerouslySetInnerHTML={{
                  __html: company?.body
                    ? DOMPurify.sanitize(company?.body.replace(iframeRegex, ''))
                    : ''
                }}></div>
            </div>
          )}

          <h3 className={classes.subtitle}>Search Other {company.name} Jobs</h3>
        </div>
      </section>

      {company && pageRendered && <JobsSearch clientId={company._id} />}
      {showLeadAcquisition && (
        <LeadAcquisitionModal
          open={showLeadAcquisition}
          handleClose={setShowLeadAcquisition}
        />
      )}
    </>
  )
}

export async function getStaticPaths() {
  return { paths: [], fallback: true }
}

export const getStaticProps: GetStaticProps = async (context) => {
  const slug = context.params?.slug
  try {
    const { data } = await apolloClient.query({
      query: GET_CLIENT_DETAIL_QUERY,
      variables: {
        slug
      }
    })

    const company = data.clientBySlug
    if (!company) {
      throw new Error(`No company found by slug ${slug}`)
    }
    return {
      revalidate: 60 * 5,
      props: {
        company
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`error while executin getStaticProps of news ${slug}`, error)
    return {
      notFound: true
    }
  }
}
